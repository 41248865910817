import { useState, useEffect } from 'react'
import useUrlPair from './useUrlPair'

export default function useKeyword() {
  const query = useUrlPair()
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    setKeyword(query.keyword)
  }, [query])

  return keyword || ''
}
