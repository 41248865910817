import { useStaticQuery, graphql } from 'gatsby'

export default function useYoutubeQRImage() {
  const result = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "youtube-qr.png" }) {
        childImageSharp {
          fluid {
            src
            srcSet
            aspectRatio
            sizes
            base64
          }
        }
      }
    }
  `)

  return result.file.childImageSharp.fluid
}
