import { useState, useEffect } from 'react'
import { fromEntries } from '../utils'

export default function useUrlPair() {
  const [query, setQuery] = useState<any>({})
  const { search } =
    typeof window === 'undefined' ? { search: '' } : window.location

  useEffect(() => {
    setQuery(
      fromEntries(
        search
          .substr(1)
          .split('&')
          .map((s: string) => {
            const [key, value] = s.split('=')
            return [key, decodeURI(value)]
          }) as [string, string][]
      )
    )
  }, [search])

  return query
}
