import React, { ReactNode } from 'react'
import backgroundUrl from '../images/background.png'
import styled from 'styled-components'

export default function Background() {
  return <Container style={{ backgroundImage: `url('${backgroundUrl}')` }} />
}

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: transparent no-repeat center center;
  background-size: cover;
  flex-direction: column;
`
