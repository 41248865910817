import { useState, useEffect } from 'react'
import { tupleOf } from '../utils'

export default function useControllerState<T>(initial: T) {
  const [state, setState] = useState(initial)

  useEffect(() => setState(initial), [initial])

  return tupleOf(state, setState)
}
