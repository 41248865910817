import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'
import { PopularTopic } from '../types'
import { fromEntries, tupleOf } from '../utils'

function keysToLowerCase({ time_stamp, date_string, ...obj }: Object) {
  const result = fromEntries(
    Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]),
  )
  return { ...result, timestamp: time_stamp * 1000, dateString: date_string }
}

function fetchPopularTopics(): Promise<PopularTopic[]> {
  return axios
    .get('/api/popular-topics')
    .then((resp) => resp.data.map(keysToLowerCase))
}

export default function usePopularTopics() {
  const [topics, setTopics] = useState<PopularTopic[]>([])

  const refresh = useCallback(() => fetchPopularTopics().then(setTopics), [
    setTopics,
  ])

  useEffect(() => {
    refresh()
  }, [refresh])

  return tupleOf(topics, refresh)
}
