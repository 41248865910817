import React from 'react'
import styled from 'styled-components'
import Icon from './icon'
import { touchable } from './global-style'

interface Props {
  color?: 'white' | 'black'
  onClick(): void
}

export default function Refresh({ color = 'white', onClick }: Props) {
  return (
    <Container
      style={{ color: color === 'white' ? 'white' : '#3a3f45' }}
      onClick={onClick}
    >
      <Icon name={color === 'white' ? 'refresh' : 'refreshBlack'} />
      <span>换一批</span>
    </Container>
  )
}

const Container = styled.div`
  ${touchable}

  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 16px;

  > * + * {
    margin-left: 4px;
  }
`
