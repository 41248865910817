import React from 'react'
import styled from 'styled-components'
import { PopularTopic } from '../types'
import { Link } from 'gatsby'
import topicPathOf from '../topic-path'

interface Props {
  source: PopularTopic[]
  minimized?: boolean
}

export default function PopularTopics({ source, minimized = false }: Props) {
  return (
    <Container className={minimized ? 'minimized' : ''}>
      <Badge>干货热榜</Badge>
      <Body>
        <LinkGroup>
          {source.slice(0, 6).map((t) => (
            <Link key={t.id} to={topicPathOf(t)}>
              <u>{t.title}</u>
            </Link>
          ))}
        </LinkGroup>
        {!minimized && source.slice(7, 13).length > 0 && (
          <LinkGroup>
            {source.slice(7, 13).map((t) => (
              <Link key={t.id} to={topicPathOf(t)}>
                <u>{t.title}</u>
              </Link>
            ))}
          </LinkGroup>
        )}
      </Body>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  color: white;

  &.minimized {
    font-size: 12px;
    line-height: 16px;
    color: #3a3f45;
  }

  > * + * {
    margin-top: 16px;
  }
`

const Badge = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: white;
  background: #ff9234;
  border-radius: 6px;
  padding: 0 6px;
`

const Body = styled.div`
  flex-direction: row;

  > * + * {
    margin-left: 128px;
  }
`

const LinkGroup = styled.div`
  flex: 1;
  line-height: 1.4em;

  > * + * {
    margin-top: 24px;
  }

  ${Container}.minimized & > * + * {
    margin-top: 16px;
  }
`
