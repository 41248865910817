import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Icon from '../icon'
import useKeyword from '../../hooks/useKeyword'
import useControllerState from '../../hooks/useControlledState'

interface Props {
  size?: 'large' | 'small'
}

export default function SearchBar({ size = 'large' }: Props) {
  const initialKeyword = useKeyword()
  const [keyword, setKeyword] = useControllerState(initialKeyword)

  const onSearch = () => {
    if (keyword) {
      navigate(`/topics/?keyword=${keyword}`)
    }
  }

  return (
    <Container className={size === 'small' ? 'small' : ''}>
      <InputBox
        placeholder="想要知道点什么？"
        value={keyword}
        onChange={(e) => setKeyword(e.currentTarget.value)}
        onKeyPress={(e) => e.key === 'Enter' && onSearch()}
      />
      <SearchButton onClick={onSearch}>
        <Icon name="search" />
      </SearchButton>
    </Container>
  )
}

const Container = styled.div`
  flex-direction: row;
  background: #f9f9f9;
  border-radius: 5px;
  overflow: hidden;
  height: 50px;
  width: 100%;

  &.small {
    width: 428px;
    height: 40px;
    border: 1px solid #c4c4c4;
  }
`

const InputBox = styled.input`
  flex: 1;
  appearance: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 16px 28px;
  font-size: 14px;
  line-height: 18px;
  background: transparent;

  .small > & {
    padding: 16px;
  }
`

const SearchButton = styled.button`
  background: #ff9234;
  width: 80px;
  cursor: pointer;
`
