import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode | ReactNode[]
  content: ReactNode | ReactNode[]
}

export default function Popover({ children, content }: Props) {
  return (
    <Container>
      {children}
      <PopoverContainer>{content}</PopoverContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const PopoverContainer = styled.div`
  position: absolute;
  background: white;
  visibility: hidden;
  bottom: 100%;
  margin-bottom: 20px;
  left: 50%;
  margin-left: -120px;
  width: 240px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 20;

  ${Container}:hover > & {
    visibility: visible;
  }

  &::after {
    content: '';
    width: 0px;
    height: 0px;
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
  }
`
