import React from 'react'
import styled from 'styled-components'
import Icon from '../icon'
import Popover from '../popover'
import useWechatQRImage from './useWechatQRImage'
import GatsbyImage from 'gatsby-image'
import useYoutubeQRImage from './useYoutubeQRImage'
import useZhihuQRImage from './useZhihuQRImage'

export default function SocialMedia() {
  const wechatImage = useWechatQRImage()
  const youteImage = useYoutubeQRImage()
  const zhihuImage = useZhihuQRImage()

  return (
    <Container>
      <Platforms>
        <Popover content={<GatsbyImage fluid={wechatImage}/>}>
          <a
            href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU3OTY4NDUzNQ==&scene=124#wechat_redirect"
            target="_blank"
          >
            <Icon name="wechat"/>
          </a>
        </Popover>
        <a
          href="https://www.youtube.com/channel/UCrRtZVPa2w_0pFhJ7Fd3-nQ"
          target="_blank"
        >
          <Icon name="youtube"/>
        </a>
        <a
          href="https://www.zhihu.com/org/rui-sheng-lu-mei-fa-lu-zi-xun"
          target="_blank"
        >
          <Icon name="zhihu"/>
        </a>
      </Platforms>
      <Copyright>Copyright © 2020 AILaw Inc. All rights reserved</Copyright>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;

  > * + * {
    margin-top: 18px;
  }
`

const Platforms = styled.div`
  flex-direction: row;

  > * + * {
    margin-left: 28px;
  }
`

const Copyright = styled.div`
  font-size: 9px;
  line-height: 12px;
  color: #ffffff;
`
