import React from 'react'
import Icon, { IconName } from './icon'

interface Props {
  size: 'large' | 'small' | 'mini'
}

export default function Logo({ size }: Props) {
  const iconName = `${size}Logo` as IconName
  return <Icon name={iconName} />
}
